.bsc {
  fill: #f0b90b;
}
.bsc:hover {
  fill: white;
}

.ftm {
  fill: #fff;
}
.ftm:hover {
  fill: white;
}
